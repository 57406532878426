import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import * as Responsive from '../Responsive';

//images
import Bodegon from '../../css/images/mixer-product.png';


const Floatings = ({ noBall }) => {
	return (
		<div className="floatings">
			<Responsive.Desktop>
				{!noBall && (
					<Parallax
						translateY={[-20, 20]}
						speed={10}
						className="bodegon"
					>
						<img src={Bodegon} alt="Bodegon" className="img-fluid" />
					</Parallax>
				)}
			</Responsive.Desktop>
		</div>
	);
};

export default Floatings;
