import React from 'react';
import { FormGroup, Label, Input, FormFeedback, Col } from 'reactstrap';
import { useField } from 'formik';

const InputForm = ({
	label,
	options,
	isRow = false,
	rowBig,
	hideLabel,
	type = 'text',
	...props
}) => {
	const [field, meta] = useField(props.name);

	return (
		<FormGroup className="mb-2" row={isRow}>
			{!hideLabel && (
				<Label
					className="f-medium"
					sm={isRow ? (rowBig ? 6 : 3) : 12}
					for={props.name}
				>
					{label}
				</Label>
			)}
			<Col sm={isRow ? (rowBig ? 6 : 9) : 12}>
				<Input
					{...field}
					{...props}
					type={type}
					autoComplete="off"
					placeholder={label}
					id={props.name}
					invalid={meta.touched && meta.error ? true : false}
				>
					{options &&
						options.map((option) => (
							<option
								key={option.value}
								value={option.value}
								disabled={option?.maxPartDate ? new Date(option.maxPartDate) > new Date() ? false: true : false}
							>
								{option.label}
							</option>
						))}
				</Input>
				{meta.touched && meta.error && (
					<FormFeedback>{meta.error}</FormFeedback>
				)}
			</Col>
		</FormGroup>
	);
};

export default InputForm;
