import React from 'react';

//comps
import FormPromo from './comps/FormPromo';
import * as Responsive from '../components/Responsive';
import Floatings from '../components/common/Floatings';

//bs
import { Container, Row, Col } from 'reactstrap';

//images
import Paso1Img from '../css/images/paso-01.png';
import Paso2Img from '../css/images/paso-02.png';
import Paso3Img from '../css/images/paso-03.png';
import BodegonMobile from '../css/images/producto-mobile.png';

const HomePage = () => {
	const currentDate = new Date();
	const promoEndDate = new Date('2024-07-20T23:59:59');
	//const promoStart = new Date('2024-06-09T23:59:59');

	return (
		<div className="home-page">
			<div className="info">
				<Floatings />

				<Container>
					<Row>
						<Col md="8" className="mx-auto">
							<div className="content-1">
								<h1>¿Qué puedes conseguir?</h1>
								<div className="premio-info">
									<div>UNA ENTRADA DOBLE VIP</div>
									<div>PARA VIVIR LA EXPERIENCIA</div>
									<div>STARLITE OCCIDENT</div>
								</div>
							</div>

							<Responsive.Mobile>
							<div className="bodegon-mobile">
								<img src={BodegonMobile} alt="MixerPerf" className="img-fluid" />
							</div>
						</Responsive.Mobile>

							<div className="content-2 mt-4 text-center">
								<h1>¿Cómo participar?</h1>
								<div className="pasos">
									<Row>
										<Col sm={4}>
											<div className="step">
												<img
													src={Paso1Img}
													alt="Paso 1"
													className="img-fluid"
												/>
												<div className="info">
													Compra 8 Latas de Schweppes 25cl o 1 Cesta de Vidrio
													20cl o Selection
												</div>
											</div>
										</Col>
										<Col sm={4}>
											<div className="step">
												<img
													src={Paso2Img}
													alt="Paso 2"
													className="img-fluid"
												/>
												<div className="info slim">
													Sube una foto del ticket de compra
												</div>
											</div>
										</Col>
										<Col sm={4}>
											<div className="step">
												<img
													src={Paso3Img}
													alt="Paso 3"
													className="img-fluid"
												/>
												<div className="info slim">
													Descubre al momento si te vas de concierto
												</div>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md="12" className="mt-4 text-center">
							<div className="cta">
								¡Cuánto más participes más posibilidades de ganar!
							</div>
							<div className="promo-terms">
								Promoción válida del 10 de junio al 20 de julio de 2024
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="participa">
				<Container>
					<Row>
						<Col md="6" className="mx-auto">
							{currentDate > promoEndDate ? (
								<div className="my-5 py-5">
									<h4 className="text-center my-5 py-5">
										La promoción ha finalizado. <strong>¡Gracias por participar!</strong>
									</h4>
								</div>
							) : (
								<FormPromo />
							)}
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default HomePage;
