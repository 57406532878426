import React from 'react';

//bs
import { Container, Row, Col } from 'reactstrap';

//comps
import { NavLink } from 'react-router-dom';
import * as Responsive from './Responsive';

//image
import titular from '../css/images/claim-header-des.png';
import titularMobile from '../css/images/claim-header-mob.png';

import logoTonica from '../css/images/logo-tonica.png';
import logoStar from '../css/images/logo-starlite.png';

const Header = () => {
	return (
		<div>
			<Container>
				<Row>
					<Col md="8" className="mx-auto mt-5">
						<Row>
							<Col md="12" className="text-center mb-4">
								<NavLink to="/">
									<Responsive.Desktop>
										<img
											src={titular}
											alt="Este verano te llevamos de festival"
											className="img-fluid"
										/>
									</Responsive.Desktop>
									<Responsive.Mobile>
										<img
											src={titularMobile}
											alt="Este verano te llevamos de festival"
											className="img-fluid"
										/>
									</Responsive.Mobile>
								</NavLink>
							</Col>
							<Col md="12">
								<Row>
									<Col xs="6" className="text-center">
										<img
											src={logoTonica}
											alt="Tónica Logo"
											className="img-fluid"
										/>
									</Col>
									<Col xs="6" className="text-center">
										<img
											src={logoStar}
											alt="Tónica Starlite"
											className="img-fluid"
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Header;
